<template>
  <div>
    <!-- SNACKBAR   -->
    <v-snackbar
        app
        top
        right
        transition='slide-x-reverse-transition'
        v-model="ui.snackbar.show"
        :color="ui.snackbar.type"
        :timeout="ui.snackbar.timeout"
    >
      <v-icon> {{ ui.snackbar.icon }}</v-icon>
      {{ ui.snackbar.message }}
      <template v-slot:action="{ attrs }">
        <v-icon @click="ui.snackbar.show = false" v-bind="attrs"> mdi-close</v-icon>
      </template>
    </v-snackbar>
    <!--END SNACKBAR   -->

    <!-- BUTTON ADD LICENCE -->
    <v-card class="mb-4 text-end" elevation="0" color="transparent">
      <v-btn outlined class="ml-2" color="primary" @click="start">
        {{ $t('dashboard.license.button') }}
      </v-btn>
    </v-card>
    <!-- END BUTTON ADD LICENCE -->

    <!-- DIALOG ADD NEW LICENCE    -->
    <v-dialog
        v-model="show_dialog_add"
        width="500"
    >
      <v-overlay :value="add_loading" absolute>
        <v-progress-circular
            indeterminate
            size="64"
        ></v-progress-circular>
      </v-overlay>

      <!-- CARD CONTENT ADD LICENSE CODE     -->
      <v-card v-if="is_email_confirmed && !is_new_private_room">
        <v-card-title class="primary white--text justify-space-between">
          {{ $t('dashboard.license.form.add_license.title') }}
          <v-icon color="white" @click="show_dialog_add=false">mdi-close</v-icon>
        </v-card-title>
        <v-card-text class="pt-3 pb-3">
          <v-alert
              v-model="ui.alert.enable"
              :dismissible="ui.alert.dismissible"
              :icon="ui.alert.icon"
              :type="ui.alert.type"
              class="mb-6 mt-1 "
              dense
          >
            {{ ui.alert.message }}
          </v-alert>
          {{ $t('dashboard.license.form.add_license.data.code') }}
          <v-text-field class="pt-3 pb-3"
                        v-model="add_licence_code"
                        outlined
                        dense
                        autofocus
                        hide-details
                        @keyup.enter="licenceAudit"
          ></v-text-field>

        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="success"
              text
              @click="licenceAudit"
              :disabled="_.isEmpty(add_licence_code)"
          >
            {{ $t('dashboard.license.form.add_license.button.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>

      <!--  CREATING A NEW ROOM AND SELECTING PACKAGE    -->
      <v-card v-if="is_new_private_room">
        <v-card-title class="primary white--text justify-space-between">
          {{ $t('dashboard.license.form.add_license.title') }}
          <v-icon color="white" @click="show_dialog_add=false">mdi-close</v-icon>
        </v-card-title>
        <v-card-text class="pt-3 pb-3">
          <v-alert
              v-model="ui.alert.enable"
              :dismissible="ui.alert.dismissible"
              :icon="ui.alert.icon"
              :type="ui.alert.type"
              class="mb-6 mt-1 "
              dense
          >
            {{ ui.alert.message }}
          </v-alert>

          <!--  IF ADDING LICENSE TO ALREADY EXISTING ROOM        -->
          <div v-if="!_.isEmpty(existing_rooms) && !btn_new_room">
            <div>
              {{ add_license_text_helper.existing_room }}
            </div>
            <v-select
                class="pt-3 pb-3"
                v-model="selected_room"
                :items="existing_rooms"
                item-text="room_name"
                item-value="room_key"
                :label="this.$t('dashboard.license.form.add_license.data.existing_room.label')"
                dense
                clearable
                outlined
                return-object
            ></v-select>
            <v-btn
                class=""
                color="primary"
                outlined
                @click="btn_new_room = true"
            >
              {{ add_license_text_helper.btn_new_room }}
            </v-btn>
          </div>
          <!-- END IF ADDING LICENSE TO ALREADY EXISTING ROOM         -->

          <!-- IF ADDING LICENSE AND CREATING NEW ROOM         -->
          <div v-if="btn_new_room">
            {{ add_license_text_helper.new_room }}
            <v-text-field class="pt-3 pb-3"
                          v-model="room_name"
                          outlined
                          dense
                          autofocus
                          hide-details
            ></v-text-field>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="success"
              text
              @click="addLicence"
              :disabled="btn_new_room ? _.isEmpty(room_name) : _.isEmpty(selected_room)"
          >
            {{ $t('dashboard.license.form.add_license.data.new_room.button') }}
          </v-btn>
        </v-card-actions>
      </v-card>


      <v-card v-else-if="!is_email_confirmed">
        <v-card-title class="primary white--text justify-space-between">
          {{ $t('dashboard.license.form.confirm_email.title') }}
          <v-icon color="white" @click="show_dialog_add=false">mdi-close</v-icon>
        </v-card-title>
        <v-card-text class="pt-3 pb-3">
          <v-alert
              v-model="ui.alert.enable"
              :dismissible="ui.alert.dismissible"
              :icon="ui.alert.icon"
              :type="ui.alert.type"
              class="mb-6 mt-1 "
              dense
          >
            {{ ui.alert.message }}
          </v-alert>
          {{
            $t('dashboard.license.form.confirm_email.text')
          }}<br>
          <v-text-field class="pt-3 pb-3"
                        v-model="token_confirmation"
                        outlined
                        dense
                        autofocus
                        :placeholder="this.$t('dashboard.license.form.confirm_email.code.placeholder')"
                        hide-details
          ></v-text-field>
          <v-spacer></v-spacer>
          <div class="text-caption">
            {{
              $t('dashboard.license.form.confirm_email.no_email')
            }}
            <a href="mailto:support@sofa-collaboration.com">support@sofa-collaboration.com</a><br><br>
          </div>
          <div class="text-caption">
            <a @click="generateConfirmation">{{ $t('dashboard.license.form.confirm_email.resend') }}</a><br>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="success"
              text
              :disabled="_.isEmpty(token_confirmation)"
              @click="confirmEmail"
          >
            {{ $t('dashboard.license.form.confirm_email.button.confirm') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- LIST OF ROOMS  -->
    <v-card elevation="0">
      <v-overlay :value="loading" absolute>
        <v-progress-circular
            indeterminate
            size="64"
        ></v-progress-circular>
      </v-overlay>
      <v-card-text v-if="_.size(activeTabs) === 0 && !loading">{{
          $t('dashboard.room.no_room')
                                                               }}
      </v-card-text>
      <v-tabs v-else
              background-color="white accent-4"
              v-model="tab"
      >
        <v-tab
            v-for="item in activeTabs"
            :key="item.ref"
            @click="switchTab(item.ref)"
        >
          {{ item.tab }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item
            v-for="item in activeTabs"
            :key="item.ref"
        >
          <v-card flat>
            <v-card-text class="px-0 pb-0" right>
              <div class="px-9">
                <div class="row justify-end">
                  <v-col cols="12" sm="4">
                    <v-text-field
                        v-model="search">
                      <v-icon slot="prepend" color="primary">mdi-magnify</v-icon>
                    </v-text-field>
                  </v-col>

                </div>
              </div>
              <v-data-table :headers="datatable.headers"
                            :items="item.content"
                            :items-per-page.sync="datatable.items_per_page"
                            :search="search"
                            @pagination="paginationHandler"
                            class="datatable"
              >
                <template v-slot:item.room_name="{ item }">
                <span class="font-weight-bold">
                  {{ item.room_name }}
                </span>
                </template>
                <template v-slot:item.online="{ item }" class="text-center">
                  <v-icon v-if="item.room_online" color="green">mdi-check</v-icon>
                  <v-icon clas v-else color="red">mdi-close</v-icon>
                </template>
                <template v-slot:item.license="{ item }" class="text-center">
                  <ul class="pl-0 caption">
                    <li v-for="i in item.license" :key="i.code">
                      {{
                        i.catalog + $t('dashboard.room.list.datatable.valid') + new Date(i.expires_at).toLocaleDateString('en-gb', date_format_options_list).replace(',', '')
                      }}
                    </li>
                  </ul>
                  <span class="font-weight-bold">
                  {{ item.license.code }}
                </span>
                </template>
                <!--                <template v-slot:item.code="{ item }" class="text-center">
                                <span class="font-weight-bold">
                                  {{ item.license }}
                                </span>
                                </template>-->
                <template v-slot:item.encrypted="{ item }" class="text-center">
                  <v-icon v-if="item.room_crypted" color="green">mdi-check</v-icon>
                  <v-icon clas v-else color="red">mdi-close</v-icon>
                </template>
                <template v-slot:item.active="{ item }" class="text-center">
                  <v-icon v-if="item.room_active" color="green">mdi-check</v-icon>
                  <v-icon clas v-else color="red">mdi-close</v-icon>
                </template>
                <template v-slot:item.modify="{ item }" class="text-center">
                  <v-icon @click="goToRoom(item)" color="primary">mdi-cog
                  </v-icon>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
    <!-- END LIST OF ROOMS  -->
  </div>
</template>

<script>
import keepSessionData from "@/tools/KeepSessionData";

export default {
  name: "List.vue",
  data() {
    return {
      loading: false,
      ui: {
        alert: {
          enable: false,
          dismissible: false,
          icon: 'mdi-check',
          type: 'info',
          message: 'Test'
        },
        snackbar: {
          show: false,
          icon: '',
          message: '',
          type: '',
          timeout: -1,
        }
      },

      date_format_options_list: {
        year: "numeric",
        month: 'numeric',
        day: 'numeric'
      },

      // add licence process
      is_email_confirmed: true, // is email confirmed for user
      token_confirmation: '', // email token confirmation for user

      add_licence_code: '', // license code for room
      license_type: null, // type of license, can be 0 for private, 1 for team and 2 for public
      is_new_private_room: false, // is a new room or adding to existent
      room_name: '', // new room name
      selected_room: {}, // user selected room to renew license
      existing_rooms: [], // fetch list of rooms
      btn_new_room: false,
      show_dialog_add: false, // show license dialog add
      add_loading: false, // dialog loader
      add_license_text_helper: {
        existing_room: 'Add license to one of your rooms:',
        btn_new_room: 'New room',
        new_room: 'Nom de la salle:',
      },

      rules: {
        required: value => !!value || 'Required.',
      },

      // tabs ui
      tab: null,
      room_type_active: '',
      items: [
        {tab: this.$t('dashboard.room.room_type.team'), ref: 'team', active: false},
        {tab: this.$t('dashboard.room.room_type.private'), ref: 'private', active: false},
        {tab: this.$t('dashboard.room.room_type.public'), ref: 'public', active: false}
      ],

      // room list datatable
      search: '',
      datatable: {
        search: '',
        headers: [],
        options: {},
        current_page: 1,
        items_per_page: parseInt(this.restoreData('pagination',5)),
      }
    }
  },
  computed: {
    /**
     * Filters tab items that are active
     * */
    activeTabs: function () {
      return this._.filter(this.items, 'active')
    }
  },
  watch: {
    tab: function () {
      switch (this.activeTabs[this.tab].ref) {
        case 'team':
          this.room_type_active = 'team'
          break
        case 'private':
          this.room_type_active = 'private'
          break
        case 'public' :
          this.room_type_active = 'public'
          break
        default:
          this.room_type_active = 'Salle'
      }
    },
    is_email_confirmed: function () {
      this.ui.alert.enable = false
    },
    /**
     * Clean add licence dialog on close
     * */
    show_dialog_add: function () {
      if (this.show_dialog_add === false) {
        this.ui.alert.enable = false
        this.cleanForm()
      }
    },
    /**
     * Change dialog add text depending on the type of license
     * */
    license_type: function () {
      switch (this.license_type) {
        case (0): //PRIVATE
          break
        case (1): //TEAM
          break
        case (2): //PUBLIC
          this.add_license_text_helper.btn_new_room = 'New public room'
          this.add_license_text_helper.new_room = 'Nom de la salle publique:'
          break
      }

    }
  },
  mixins: [keepSessionData],
  methods: {
    /**
     * @param {Object} e
     */
    paginationHandler(e) {
      if (this.datatable.items_per_page === e.itemsPerPage)
        this.keepData('pagination', e.itemsPerPage)
    },
    alert(type, icon, message, dismissible = true) {
      this.ui.alert.icon = icon
      this.ui.alert.type = type
      this.ui.alert.message = message
      this.ui.alert.dismissible = dismissible
      this.ui.alert.enable = true
    },
    goToRoom(item) {
      this.$router.push({
        name: 'GeneralRoomEdit', params: {room_key: item.room_key}
      })
    },
    cleanForm() {
      this.add_licence_code = ''
      this.token_confirmation = ''
      this.email_to_add = ''
      this.is_new_private_room = false
      this.btn_new_room = false
      this.selected_room = {}
      this.room_name = ''
    },
    /**
     * Fetch team rooms
     * @return {Promise<void>}
     * */
    async fetchTeamRooms() {
      try {
        this.loading = true

        const response = (await this.axios.post('/room/team/list', {
          auth_token: this.$session.get('token')
        })).data

        this.items[0].content = this._.isEmpty(response.team_rooms) ? [] : response.team_rooms

        if (this._.size(this.items[0].content) > 0) {
          return this._.reduce(this.items, function (r, i) {
            if (i.ref === 'team')
              i.active = true
          }, [])
        }

      } catch (e) {
        if (!this._.isUndefined(e.response?.status)) {
          switch (parseInt(e.response.status)) {
              // invalid params
            case 400:
              this.snackbar('error', 'mdi-alert-octagon', this.$t('dashboard.room.list.team_room.error.invalid_params'), -1)
              break;

              // invalid token
            case 401:
              this.$session.destroy()
              await this.$router.push({name: 'Login'})
              break;

            default:
              this.snackbar('error', 'mdi-alert-octagon', this.$t('dashboard.room.list.team_room.error.unknown'), -1)
          }
        }
      } finally {
        this.loading = false
      }
    },
    /**
     * Fetch public rooms
     *  @return {Promise<void>}
     * */
    async fetchPublicRooms() {
      try {
        this.loading = true

        const response = (await this.axios.post('/room/public/list', {
          auth_token: this.$session.get('token')
        })).data

        this.items[2].content = this._.isEmpty(response.public_rooms) ? [] : response.public_rooms

        if (this._.size(this.items[2].content) > 0) {
          return this._.reduce(this.items, function (r, i) {
            if (i.ref === 'public') {
              i.active = true
            }

          }, [])
        }

      } catch (e) {
        if (!this._.isUndefined(e.response?.status)) {
          switch (parseInt(e.response.status)) {
              // invalid params
            case 400:
              this.alert('error', 'mdi-alert-octagon', 'Impossible de récupérer la liste des salles publiques. Veuillez réessayer.', false)
              break;

              // invalid token
            case 401:
              this.$session.destroy()
              await this.$router.push({name: 'Login'})
              break;

            default:
              this.alert('error', 'mdi-alert-octagon', 'Impossible de récupérer la liste des salles publiques. Veuillez réessayer.', false)
          }
        }
      } finally {
        this.loading = false
      }
    },
    /**
     * Fetch private room
     * @return {Promise<void>}
     */
    async fetchPrivateRoom() {
      try {
        this.loading = true

        const response = (await this.axios.post('/room/private/list', {
          auth_token: this.$session.get('token')
        })).data

        this.items[1].content = this._.isEmpty(response.private_room) ? [] : response.private_room
        if (this._.size(this.items[1].content) > 0) {
          return this._.reduce(this.items, function (r, i) {
            if (i.ref === 'private')
              i.active = true
          }, [])
        }

      } catch (e) {
        if (!this._.isUndefined(e.response?.status)) {
          switch (parseInt(e.response.status)) {
              // invalid params
            case 400:
              this.alert('error', 'mdi-alert-octagon', this.$t('dashboard.room.list.private_room.error.invalid_params'), false)
              break;

              // invalid token
            case 401:
              this.$session.destroy()
              await this.$router.push({name: 'Login'})
              break;

            default:
              this.alert('error', 'mdi-alert-octagon', this.$t('dashboard.room.list.private_room.error.unknown'), false)
          }
        }
      } finally {
        this.loading = false
      }
    },
    /**
     * Fetch room list depending on type on tab switch
     * */
    switchTab(tab) {
      this.ui.alert.enable = false

      switch (tab) {
        case 'team':
          this.room_type_active = 'team'
          this.fetchTeamRooms()
          break;
        case 'private':
          this.room_type_active = 'private'
          this.fetchPrivateRoom()
          break;
        case 'public' :
          this.room_type_active = 'public'
          this.fetchPublicRooms()
          break;
      }
    },
    snackbar(type, icon, message, timeout, multiline) {
      this.ui.snackbar.message = message
      this.ui.snackbar.icon = icon
      this.ui.snackbar.type = type
      this.ui.snackbar.timeout = timeout
      this.ui.snackbar.show = true
      this.ui.snackbar.multiline = multiline
    },
    async start() {
      await this.checkEmailConfirmed()
    },
    /**
     * Check if user email is confirmed
     *
     * If not :
     *      Send confirmation token (show dialog with confirmation token)
     * If yes:
     *      Show dialog with add licence
     * @return {Promise<void>}
     */
    async checkEmailConfirmed() {
      try {
        this.loading = true
        this.add_loading = true

        await this.axios.post('/user/email-confirmed', {
          auth_token: !this._.isUndefined(this.$session.get('token')) ? this.$session.get('token') : null
        })

        this.is_email_confirmed = true
        this.show_dialog_add = true

      } catch (e) {
        if (!this._.isUndefined(e.response?.status)) {
          switch (parseInt(e.response.status)) {

              //Invalid parameters
            case 400:
              this.snackbar('error', 'mdi-alert-circle-outline', this.$t('dashboard.license.form.confirm_email.check_email_confirmed.error.invalid_params'), '4000')
              break

              //Invalid token
            case 401:
              this.$session.destroy()
              await this.$router.push({name: "Home"})
              break

              // User email not confirmed => send confirmation token by email
            case 402:
              this.is_email_confirmed = false
              await this.generateConfirmation()
              break

            default:
              this.snackbar('error', 'mdi-alert-circle-outline', this.$t('dashboard.license.form.confirm_email.check_email_confirmed.error.unknown'), '4000')
          }
        }
      } finally {
        this.loading = false
        this.show_dialog_add = true
        this.add_loading = false
      }
    },
    /**
     * Generates email confirmation token and sends email to user with code
     *
     * */
    async generateConfirmation() {
      try {
        this.add_loading = true

        await this.axios.post('/user/email/token', {
          auth_token: !this._.isUndefined(this.$session.get('token')) ? this.$session.get('token') : null
        })

      } catch (e) {
        if (!this._.isUndefined(e.response?.status)) {
          switch (parseInt(e.response.status)) {

              //Invalid parameters
            case 400:
              this.snackbar('error', 'mdi-alert-circle-outline', this.$t('dashboard.license.form.confirm_email.generate_confirmation_token.error.invalid_params'), '4000')
              break

              //Invalid token
            case 401:
              this.$session.destroy()
              await this.$router.push({name: "Home"})
              break

              // User email not confirmed => send confirmation token by email
            case 402:
              this.is_email_confirmed = false
              this.ui.alert.enable = false
              break

            default:
              this.snackbar('error', 'mdi-alert-circle-outline', this.$t('dashboard.license.form.confirm_email.generate_confirmation_token.error.unknown'), '4000')
          }
        }
      } finally {
        this.add_loading = false
      }
    },
    /**
     * Confirm user email using token send by email
     * */
    async confirmEmail() {
      try {
        this.add_loading = true

        await this.axios.post('/user/email/confirm', {
          auth_token: !this._.isUndefined(this.$session.get('token')) ? this.$session.get('token') : null,
          token: this.token_confirmation
        })

        this.is_email_confirmed = true
        this.show_dialog_add = true

      } catch (e) {
        if (!this._.isUndefined(e.response?.status)) {
          switch (parseInt(e.response.status)) {

              //Invalid parameters
            case 400:
              this.snackbar('error', 'mdi-alert-circle-outline', this.$t('dashboard.license.form.confirm_email.error.invalid_params'), '4000')
              break

              //Invalid token
            case 401:
              this.$session.destroy()
              await this.$router.push({name: "Home"})
              break

              // Confirmation token mismatch
            case 403:
              this.alert('error', 'mdi-alert-octagon', this.$t('dashboard.license.form.confirm_email.error.token_mismatch'), true)
              break

            default:
              this.snackbar('error', 'mdi-alert-circle-outline', this.$t('dashboard.license.form.confirm_email.error.unknown'), '4000')
          }
        }
      } finally {
        this.add_loading = false
      }
    },

    /**
     * Gets info from license code
     * Type of license
     * Packages available for license
     *
     * @return {Promise<void>}
     */
    async licenceAudit() {
      this.add_loading = true

      try {
        let response = (await this.axios.post('/license/audit', {
          auth_token: !this._.isUndefined(this.$session.get('token')) ? this.$session.get('token') : null,
          license_code: this.add_licence_code
        }))

        this.license_type = response.data.type

        // PRIVATE LICENSE TYPE
        if (this.license_type === 0) {

          // User already has a private room, adding licence to that room
          if (parseInt(response.status) === 201) {
            this.ui.alert.enable = false
            this.show_dialog_add = false
            this.snackbar('success', 'mdi-check-circle-outline', this.$t('dashboard.license.form.add_license.success_message_existing_room') + response.data.existing_room, 4000)
            await this.fetchPrivateRoom()
          }

          // User doesn't have a private room, fetch packages available for room
          if (parseInt(response.status) === 200) {
            this.ui.alert.enable = false
            this.is_new_private_room = true
            this.btn_new_room = true

            // update text
            this.add_license_text_helper.btn_new_room = this.$t('dashboard.license.form.add_license.data.new_private_room.button')
            this.add_license_text_helper.new_room = this.$t('dashboard.license.form.add_license.data.new_private_room.label')
          }
        }

        // TEAM LICENSE TYPE
        if (this.license_type === 1) {
          // User has other team rooms, return list of team rooms in case he wants to add licence to an existing room
          if (parseInt(response.status) === 201) {
            this.ui.alert.enable = false
            this.is_new_private_room = true
            this.existing_rooms = response.data.team_rooms
          }

          // No other team rooms, create new team room
          if (parseInt(response.status) === 200) {
            this.ui.alert.enable = false
            this.is_new_private_room = true
            this.btn_new_room = true

            this.add_license_text_helper.btn_new_room = this.$t('dashboard.license.form.add_license.data.new_team_room.button')
            this.add_license_text_helper.new_room = this.$t('dashboard.license.form.add_license.data.new_team_room.label')
          }
        }

        // PUBLIC LICENSE TYPE
        if (this.license_type === 2) {

          // User has other public rooms, return list of public rooms in case he wants to add license to an existing room
          if (parseInt(response.status) === 201) {
            this.ui.alert.enable = false
            this.is_new_private_room = true
            this.existing_rooms = response.data.public_rooms

            this.add_license_text_helper.existing_room = 'Add license to one of your public rooms:'
            this.add_license_text_helper.btn_new_room = 'New public room'
          }

          // No other public rooms, add new
          if (parseInt(response.status) === 200) {
            this.ui.alert.enable = false
            this.is_new_private_room = true
            this.btn_new_room = true

            this.add_license_text_helper.btn_new_room = 'New public room'
            this.add_license_text_helper.new_room = 'Nom de la salle publique:'
          }
        }

      } catch (e) {
        if (!this._.isUndefined(e.response?.status)) {
          switch (parseInt(e.response.status)) {
              //Invalid parameters
            case 400:
              this.alert('error', 'mdi-alert-octagon', this.$t('dashboard.license.form.add_license.error.invalid_params'), true)
              break

              //Invalid token
            case 401:
              this.$session.destroy()
              await this.$router.push({name: 'Login'})
              break;

              // Room does not exist
            case 404:
              await this.$router.push({name: "Home"}).then(() => {
                this.$root.$emit('home-error', 'no-room')
              })
              break

              // Invalid license code
            case 460:
              this.alert('error', 'mdi-alert-octagon', this.$t('dashboard.license.form.add_license.error.license_invalid'), true)
              break

              // License cannot be attached : not pending, already active or expired
            case 461:
              this.alert('error', 'mdi-alert-octagon', this.$t('dashboard.license.form.add_license.error.license_expired'), true)
              break

              // Incorrect license kind for room
            case 462:
              this.alert('error', 'mdi-alert-octagon', this.$t('dashboard.license.form.add_license.error.license_incompatible'), true)
              break

              // Method not allowed
            case 405:
              this.alert('error', 'mdi-alert-octagon', this.$t('dashboard.license.form.add_license.error.invalid_method'), true)
              break

              // Cannot save to DB
            default:
              this.alert('error', 'mdi-alert-octagon', this.$t('dashboard.license.form.add_license.error.unknown'), true)
          }
        }
      } finally {
        this.add_loading = false
      }
    },
    /**
     * Add license
     * @return {Promise<void>}
     */
    async addLicence() {
      this.add_loading = true

      try {
        await this.axios.post('/room/license/add', {
          auth_token: !this._.isUndefined(this.$session.get('token')) ? this.$session.get('token') : null,
          license_code: this.add_licence_code,
          room_name: this.room_name,
          room_key: this.selected_room.room_key,
        })

        this.show_dialog_add = false

        switch (this.license_type) {
            // fetch private
          case 0:
            await this.fetchPrivateRoom()
            break
            // fetch team
          case 1:
            await this.fetchTeamRooms()
            break
          case 2:
            await this.fetchPublicRooms()
            break
        }

        this.cleanForm()

        this.snackbar('success', 'mdi-check-circle-outline', this.$t('dashboard.license.form.add_license.success_message'))


      } catch (e) {
        if (!this._.isUndefined(e.response?.status)) {
          switch (parseInt(e.response.status)) {
              //Invalid parameters
            case 400:
              this.alert('error', 'mdi-alert-octagon', this.$t('dashboard.license.form.add_license.error.invalid_params'), true)
              break

              //Invalid token
            case 401:
              this.$session.destroy()
              await this.$router.push({name: 'Login'})
              break;

              // Room does not exist
            case 404:
              await this.$router.push({name: "Home"}).then(() => {
                this.$root.$emit('home-error', 'no-room')
              })
              break

              // Invalid license code
            case 460:
              this.alert('error', 'mdi-alert-octagon', this.$t('dashboard.license.form.add_license.error.license_invalid'), true)
              break

              // License cannot be attached : not pending, already active or expired
            case 461:
              this.alert('error', 'mdi-alert-octagon', this.$t('dashboard.license.form.add_license.error.license_expired'), true)
              break

              // Incorrect license kind for room
            case 462:
              this.alert('error', 'mdi-alert-octagon', this.$t('dashboard.license.form.add_license.error.license_incompatible'), true)
              break

              // Method not allowed
            case 405:
              this.alert('error', 'mdi-alert-octagon', this.$t('dashboard.license.form.add_license.error.invalid_method'), true)
              break

              // Cannot save to DB
            default:
              this.alert('error', 'mdi-alert-octagon', this.$t('dashboard.license.form.add_license.error.unknown'), true)
          }
        }
      } finally {
        this.add_loading = false
      }


    },
  },
  created() {
    // Define translations
    this.datatable.headers = [
      {
        value: 'room_name',
        sortable: true,
        filterable: true,
        text: this.$t('dashboard.room.list.datatable.name')
      },
      {
        value: 'online',
        text: this.$t('dashboard.room.list.datatable.online'),
        align: 'center'
      },
      {
        value: 'license',
        sortable: false,
        text: this.$t('dashboard.room.list.datatable.license')
      },
      /* {
         value: 'code',
         sortable: false,
         text: "Code de licence"
       },*/
      {
        value: 'encrypted',
        sortable: false,
        text: this.$t('dashboard.room.list.datatable.crypted'),
        align: 'center'
      },
      {
        value: 'active',
        sortable: false,
        text: this.$t('dashboard.room.list.datatable.active'),
        align: 'center'
      },
      {
        text: this.$t('dashboard.room.list.datatable.settings'),
        value: 'modify',
        sortable: false,
        align: 'center'
      }
    ]

  },
  async mounted() {
    await this.fetchTeamRooms()
    await this.fetchPrivateRoom()

    // only applies to Stories
    //await this.fetchPublicRooms()

    /**
     * Respond to event sent from API routes if:
     *  1. User does not have access to room
     *  2. Room does not exist
     */
    this.$root.$on('home-error', (data) => {
      switch (data) {
        case 'no-access':
          this.snackbar('error', 'mdi-alert-circle-outline', this.$t('dashboard.events.no_access'))
          break
        case 'no-room':
          this.snackbar('error', 'mdi-alert-circle-outline', this.$t('dashboard.events.no_room'))
          break
        default:
          this.snackbar('error', 'mdi-alert-circle-outline', this.$t('dashboard.events.unknown'))
      }
    })
  },
  beforeDestroy() {
    this.$root.$off('home-error')
  }
}
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_variables';

.l-min {
  min-width: 600px;
  min-height: 600px;
}

::v-deep {
  .datatable {
    tr {
      @media #{map-get($display-breakpoints, 'sm-and-up')} {
        th:first-child, td:first-child {
          padding-left: 36px;
        }

        th:last-child, td:last-child {
          padding-right: 36px;
        }
      }
    }
  }

  .v-tab:after {
    content: '';
    height: 60%; //You can change this if you want smaller/bigger borders
    width: 1px;

    position: absolute;
    right: 0;
    // If you want to set a smaller height and center it, change this value

    background-color: #000000; // The color of your border
  }

  .v-tab:nth-last-of-type(1):after {
    width: 0px !important;
  }
}
</style>
